<template>
  <div class="search-mobile" :class="{ 'search-mobile--active': isOpen }" v-if="isOpen">
    <div class="search-mobile__header">
      <div class="search-mobile__item search-mobile__item---input">
        <input
          ref="mobileSearchInput"
          class="search-mobile__input"
          type="text"
          :placeholder="$t('base-header-search-btn')"
          @input="onChange"
          :value="search"
          @keyup.enter="onEnter"
        >
        <button
          class="search-mobile__clear"
          type="button"
          @click="clearSearch"
        >
          <svg-icon class="search-mobile__clear-ico" name="close"></svg-icon>
        </button>
      </div>
      <div class="search-mobile__item">
        <button class="search-mobile__close" type="button" @click="closeSearch">{{ $t('product-quotation-cancel-btn') }}</button>
      </div>

    </div>
    <div class="search-mobile__data">
      <div v-if="search.length > 0 && (isLoading || results.length > 0)" class="search-mobile__box search-mobile__box--list">
        <SearchResults :is-loading="isLoading" :results="results" @setResult="setResult"/>
      </div>
      <div v-else class="search-mobile__box search-mobile__box--starter">
        <svg-icon class="search-mobile__ico" name="search-mobile"></svg-icon>
        <p class="search-mobile__message">{{$t('product-search-message-search-by-name')}}</p>
      </div>

      <div class="search-mobile__box search-mobile__box--button">
        <button class="search-mobile__button button button--full" type="button" @click="onSearchButtonClick()">
          {{$t('product-search-mobile-button')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import SearchResults from '@/components/search/SearchResults';

  import { searchBarMixin } from '@/mixins/searchBar';

  export default {
    components: { SearchResults },
    data() {
      return {
        isOpen: false,
        isAsync: true
      };
    },
    mixins: [searchBarMixin],
    mounted() {
      this.$root.$on('search-bar:open', () => {
        this.openSearch();
      });
    },
    methods: {
      openSearch() {
        document.body.classList.add('body--popup');
        this.isOpen = true;
        this.focusSearchInput();
      },
      closeSearch () {
        document.body.classList.remove('body--popup');
        this.isOpen = false;
        this.search = '';
        this.results = [];
      },
      clearSearch() {
        this.search = '';
        this.results = [];
        this.focusSearchInput();
      },
      onChange(e) {
        this.search = e.target.value;
        this.fetchResults();
      },
      setResult(result) {
        this.search = '';
        this.goToTheResultPage(result);
        this.closeSearch();
      },
      onSearchButtonClick() {
        this.goToTheSearchPage();
        this.closeSearch();
      },
      focusSearchInput() {
        this.$nextTick(() => {
          this.$refs.mobileSearchInput.focus();
        });
      },
      onEnter() {
        this.onSearchButtonClick();
      }
    }
  }
</script>

<style scoped>
  .search-mobile {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 999;
  }
  .search-mobile__header {
    display: flex;
    margin: 0 -10px;
    position: relative;
    padding: 8px 20px 8px 8px;
    background-color: var(--secondary-color-dark);
    box-sizing: border-box;
  }

  .search-mobile__item {
    position: relative;
    padding: 0 10px;
  }

  .search-mobile__item---input {
    flex-grow: 1;
  }

  .search-mobile__data {
    height: calc(100% - 56px);
    padding-bottom: 48px;
    box-sizing: border-box;
    overflow: auto;
  }

  .search-mobile__box--starter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
  }

  .search-mobile__box--list {
    padding: 10px 0;
  }

  .search-mobile__box--button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .search-mobile__input {
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    line-height: 1.25;
    background: #FFFFFF;
    border-radius: 2px;
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    font-size: 16px;
  }

  .search-mobile__clear {
    position: absolute;
    top: 13px;
    right: 23px;
    width: 14px;
    height: 14px;
  }

  .search-mobile__clear-ico {
    width: 14px;
    height: 14px;
    fill: var(--color-grey-4);
  }

  .search-mobile__close {
    height: 100%;
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.28;
    color: #FFFFFF;
    text-transform: uppercase;
  }

  .search-mobile__ico {
    margin-bottom: 32px;
    width: 80px;
    height: 80px;
    fill: var(--color-grey-4);
  }

  .search-mobile__message {
    text-align: center;
    font-size: 1rem;
    line-height: 1.375;
    color: var(--color-grey-5);
  }

</style>
