<template>
  <div id="autocomplete-results" class="search-results">
    <template v-if="!results.length ">
      <p class="search-results__loading" v-if="isLoading">{{ $t('search-results-loading-title') }}</p>
      <p class="search-results__empty search-results-empty" v-if="!isLoading  ">
        <span class="search-results-empty__title" v-if="search.length > 0">{{$t('product-search-empty-result-title')}}</span>
        <span class="search-results-empty__text" v-if="search.length > 0">{{$t('product-search-empty-result-text')}}</span>
      </p>
      <SearchResultsPopularProducts v-if="popularProducts" @setResult="setResult"/>
    </template>
    <ul v-if="results.length" class="search-results__list">
      <BaseSearchItem
        class="search-results__item"
        v-for="(result, i) in results"
        :key="i"
        :index="i"
        :arrow-counter="arrowCounter"
        :product="result.source"
        :highlight="result.highlight"
        :type="result.type"
        :photo="result.source.photo"
        @setResult="setResult(result)"
      />
    </ul>
    
  </div>
</template>
<script>
  import { TYPES } from '@/utils/types/autocomplete-link';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: "SearchResults",
    props: ["isLoading", "results", "arrowCounter", "search"],
    data() {
        return {
            TYPES
        };
    },
    computed: {
      ...mapGetters({
        popularProducts: 'search/popularProducts',
      }),
    },
    methods: {
      ...mapActions({
        fetchPopularProducts: 'search/fetchPopularProducts',
      }),
      setResult(result) {
        this.$emit("setResult", result);
      },
    },
    components:{
      BaseSearchItem:() => import('@/components/search/BaseSearchItem.vue'),
      SearchResultsPopularProducts:() => import('./SearchResultsPopularProducts.vue')
    },
    created() {
      if(!this.popularProducts) this.fetchPopularProducts();
    }
}
</script>
<style scoped>
  .search-results__loading {
    padding: 10px 20px;
    color: var(--secondary-color-dark);
  }

  .search-results-empty {
    padding: 10px 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    box-sizing: border-box;
  }

  .search-results-empty__title {
    margin-bottom: 8px;
    font-family: var(--secondary-font);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.1;
    color: #000000;
    text-align: left;
    
  }

  .search-results-empty__text {
    font-size: 1rem;
    line-height: 1.4;
    color: var(--color-grey-5);
    font-weight: 400;
    text-align: left;
  }
</style>
